import { render } from "./DelosProductCard.vue?vue&type=template&id=5062d86e&scoped=true"
import script from "./DelosProductCard.vue?vue&type=script&lang=ts"
export * from "./DelosProductCard.vue?vue&type=script&lang=ts"
script.render = render
script.__scopeId = "data-v-5062d86e"
/* hot reload */
if (module.hot) {
  script.__hmrId = "5062d86e"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('5062d86e', script)) {
    api.reload('5062d86e', script)
  }
  
  module.hot.accept("./DelosProductCard.vue?vue&type=template&id=5062d86e&scoped=true", () => {
    api.rerender('5062d86e', render)
  })

}

script.__file = "src/components/dashboard/products-and-solutions/DelosProductCard.vue"

export default script